import { RiddleData } from '../types/riddle';

// Mock data for development and fallback
export const mockRiddleData: RiddleData[] = [
  {
    id: 1,
    riddle: "Through wisdom's gate I guide the way,\nChallenging minds day by day.\nIn puzzles wrapped, in questions dressed,\nI help you learn, I help you test.\nWith every solve, with every try,\nYour knowledge grows, your spirits fly.",
    answer: "Learning",
    alternateAnswers: ["Learn", "Education", "Teaching", "Teacher", "Knowledge", "Study", "Studies"],
    difficulty: "Trailblazer",
    category: "Education",
    hints: [
      "I help you grow and develop your mind",
      "You experience me every day, especially in school",
      "I'm a lifelong journey of discovery and understanding"
    ],
    tags: ["education", "growth", "knowledge"],
    imageDescription: "A mystical tree of knowledge with glowing symbols"
  },
  {
    id: 2,
    riddle: "Silent until touched am I,\nBlack and white against the sky.\nEighty-eight of us in line,\nMaking melodies divine.\nFingers dance upon my face,\nBringing songs to every place.",
    answer: "Piano",
    alternateAnswers: ["Keyboard", "Keys", "Piano keys"],
    difficulty: "Novice Noodler",
    category: "Music",
    hints: [
      "I have black and white parts that make different sounds",
      "I'm a large musical instrument often found in concert halls",
      "My name comes from the Italian word for 'soft'"
    ],
    tags: ["music", "instrument", "keys"],
    imageDescription: "Floating piano keys transforming into musical notes"
  },
  {
    id: 3,
    riddle: "Through circuits of light I swiftly glide,\nCarrying messages far and wide.\nIn copper veins or through the air,\nConnecting worlds both near and rare.\nInvisible yet everywhere,\nA digital dance beyond compare.",
    answer: "Internet",
    alternateAnswers: ["Web", "Network", "Internet connection", "WiFi", "World Wide Web"],
    difficulty: "Puzzle Prowler",
    category: "Technology",
    hints: [
      "I'm all around you but can't be seen with naked eyes",
      "I help phones and computers talk to each other",
      "Without me, you couldn't browse websites or send messages instantly"
    ],
    tags: ["technology", "communication", "network"],
    imageDescription: "Abstract streams of light forming interconnected patterns"
  }
];