import React from 'react';
import { Send, ArrowRight } from 'lucide-react';

interface RiddleInputProps {
  userAnswer: string;
  onAnswerChange: (answer: string) => void;
  onSubmit: () => void;
  showAnswer: boolean;
  buttonText: string;
}

export default function RiddleInput({
  userAnswer,
  onAnswerChange,
  onSubmit,
  showAnswer,
  buttonText
}: RiddleInputProps) {
  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      onSubmit();
    }
  };

  return (
    <div className="flex gap-3">
      <input
        type="text"
        value={userAnswer}
        onChange={(e) => onAnswerChange(e.target.value)}
        onKeyPress={handleKeyPress}
        placeholder="Type your answer..."
        className="flex-1 rounded-lg bg-brand-darkBg border border-brand-blue/30 text-brand-cream p-3 placeholder-brand-blue/50 focus:border-brand-blue focus:ring-1 focus:ring-brand-blue"
        disabled={showAnswer}
      />
      <button
        onClick={onSubmit}
        disabled={!showAnswer && !userAnswer.trim()}
        className={`px-6 py-2 rounded-lg flex items-center gap-2 min-w-[120px] justify-center transition-all duration-300 ${
          showAnswer
            ? 'bg-brand-orange hover:bg-brand-brightOrange text-white'
            : 'bg-brand-blue hover:bg-brand-primary text-white'
        } disabled:opacity-50 disabled:cursor-not-allowed`}
      >
        {showAnswer ? (
          <>
            <ArrowRight className="h-5 w-5" />
            <span>{buttonText}</span>
          </>
        ) : (
          <>
            <Send className="h-5 w-5" />
            <span>{buttonText}</span>
          </>
        )}
      </button>
    </div>
  );
}