import React, { useState } from 'react';
import { Trophy } from 'lucide-react';
import { mockRiddleData } from '../utils/riddleData';
import DifficultySelector from './DifficultySelector';
import RiddleDisplay from './RiddleDisplay';
import RiddleInput from './RiddleInput';

export default function RiddleGame() {
  const [selectedDifficulty, setSelectedDifficulty] = useState('Random');
  const [selectedCategory, setSelectedCategory] = useState('Random');
  const [currentRiddle] = useState(mockRiddleData[0]);
  const [userAnswer, setUserAnswer] = useState('');
  const [score, setScore] = useState(0);
  const [currentHintIndex, setCurrentHintIndex] = useState(-1);
  const [feedback, setFeedback] = useState('');
  const [showHintPrompt, setShowHintPrompt] = useState(false);

  const handleSubmit = () => {
    if (!userAnswer.trim()) return;

    const normalizedUserAnswer = userAnswer.toLowerCase().trim();
    const normalizedCorrectAnswer = currentRiddle.answer.toLowerCase();
    const isCorrect = normalizedUserAnswer === normalizedCorrectAnswer;

    if (isCorrect) {
      const basePoints = 10;
      const hintPenalty = currentHintIndex + 1;
      const finalPoints = Math.max(basePoints - (hintPenalty * 2), 1);
      
      setScore(prev => prev + finalPoints);
      setFeedback(`Correct! You earned ${finalPoints} points.`);
    } else {
      setFeedback('Not quite. Would you like a hint?');
      setShowHintPrompt(true);
    }
  };

  return (
    <div className="max-w-4xl mx-auto space-y-8">
      <div className="flex justify-between items-center bg-gradient-to-r from-brand-blue/20 to-brand-orange/20 p-6 rounded-xl border border-brand-blue/30">
        <div className="flex items-center gap-3">
          <Trophy className="h-8 w-8 text-brand-orange" />
          <span className="text-2xl font-bold text-brand-cream">Score: {score}</span>
        </div>
      </div>

      <DifficultySelector
        selectedDifficulty={selectedDifficulty}
        selectedCategory={selectedCategory}
        onDifficultyChange={setSelectedDifficulty}
        onCategoryChange={setSelectedCategory}
      />

      <div className="bg-gradient-to-br from-brand-darkBlue/50 to-brand-darkBg/50 rounded-xl p-8 border border-brand-blue/20 shadow-xl">
        <RiddleDisplay
          riddle={currentRiddle}
          hints={currentRiddle.hints}
          currentHintIndex={currentHintIndex}
          onRequestHint={() => {
            setCurrentHintIndex(prev => prev + 1);
            setShowHintPrompt(false);
          }}
          feedback={feedback}
          showHintPrompt={showHintPrompt}
          isRiddleOfTheDay={true}
        />

        <div className="mt-6">
          <RiddleInput
            userAnswer={userAnswer}
            onAnswerChange={setUserAnswer}
            onSubmit={handleSubmit}
            showAnswer={feedback.includes('Correct')}
            buttonText={feedback.includes('Correct') ? 'Next Riddle' : 'Submit'}
          />
        </div>
      </div>
    </div>
  );
}