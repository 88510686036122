import React from 'react';
import { RiddleData } from '../types/riddle';
import { Brain, Tag, Lightbulb, Calendar } from 'lucide-react';

interface RiddleDisplayProps {
  riddle: RiddleData;
  hints: string[];
  currentHintIndex: number;
  onRequestHint: () => void;
  feedback: string;
  showHintPrompt: boolean;
  isRiddleOfTheDay?: boolean;
}

export default function RiddleDisplay({
  riddle,
  hints,
  currentHintIndex,
  onRequestHint,
  feedback,
  showHintPrompt,
  isRiddleOfTheDay
}: RiddleDisplayProps) {
  return (
    <div className="space-y-6">
      {isRiddleOfTheDay && (
        <div className="flex items-center gap-2 text-brand-orange mb-4">
          <Calendar className="h-6 w-6" />
          <span className="font-medium text-lg">Riddle of the Day</span>
        </div>
      )}

      <div className="flex flex-wrap items-center gap-4 text-sm">
        <div className="flex items-center gap-2 bg-brand-blue/10 text-brand-blue px-4 py-2 rounded-full border border-brand-blue/20">
          <Brain className="h-5 w-5" />
          <span className="font-medium">{riddle.difficulty}</span>
        </div>
        <div className="flex items-center gap-2 bg-brand-orange/10 text-brand-orange px-4 py-2 rounded-full border border-brand-orange/20">
          <Tag className="h-5 w-5" />
          <span className="font-medium">{riddle.category}</span>
        </div>
      </div>

      <div className="bg-brand-darkBlue rounded-xl p-8 border border-brand-blue/30">
        <pre className="text-xl font-medium text-brand-cream whitespace-pre-line leading-relaxed">
          {riddle.riddle}
        </pre>
      </div>

      {feedback && (
        <div className={`p-4 rounded-xl ${
          feedback.includes('Correct') 
            ? 'bg-green-500/10 text-green-300 border border-green-500/20' 
            : 'bg-brand-blue/10 text-brand-blue border border-brand-blue/20'
        }`}>
          {feedback}
        </div>
      )}

      {currentHintIndex > -1 && (
        <div className="space-y-3">
          {hints.slice(0, currentHintIndex + 1).map((hint, index) => (
            <div key={index} className="p-4 bg-brand-blue/10 rounded-xl flex items-start gap-3 border border-brand-blue/20">
              <Lightbulb className="h-5 w-5 text-brand-blue mt-0.5 flex-shrink-0" />
              <p className="text-brand-cream">
                <span className="font-medium">Hint {index + 1}:</span> {hint}
                <span className="text-sm text-brand-blue ml-2">(-2 points)</span>
              </p>
            </div>
          ))}
        </div>
      )}

      {(showHintPrompt || (currentHintIndex < hints.length - 1 && !feedback.includes('Correct'))) && (
        <button
          onClick={onRequestHint}
          className="flex items-center gap-2 text-brand-blue hover:text-brand-cream font-medium p-3 rounded-xl hover:bg-brand-blue/10 transition-colors"
        >
          <Lightbulb className="h-5 w-5" />
          {showHintPrompt ? "Yes, I'd like a hint (-2 points)" : "Need a hint? (-2 points)"}
        </button>
      )}
    </div>
  );
}