import React from 'react';
import { Trophy, Award, Star } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';

export default function UserProfile() {
  const { user } = useAuth();
  
  if (!user) return null;

  const badges = JSON.parse(user.badges);
  const level = Math.floor(1 + user.points / 100);
  const nextLevelPoints = level * 100;
  const progress = (user.points % 100) / 100;

  return (
    <div className="bg-white rounded-lg shadow-lg p-6">
      <div className="flex items-center gap-4 mb-6">
        <div className="bg-primary-100 rounded-full p-4">
          <Trophy className="h-8 w-8 text-primary-600" />
        </div>
        <div>
          <h2 className="text-xl font-bold">{user.username}</h2>
          <p className="text-gray-600">Level {level} Explorer</p>
        </div>
      </div>

      <div className="space-y-4">
        <div>
          <div className="flex justify-between text-sm mb-1">
            <span>Level Progress</span>
            <span>{user.points} / {nextLevelPoints} XP</span>
          </div>
          <div className="h-2 bg-gray-200 rounded-full">
            <div 
              className="h-full bg-primary-500 rounded-full transition-all"
              style={{ width: `${progress * 100}%` }}
            />
          </div>
        </div>

        <div>
          <h3 className="font-semibold mb-2 flex items-center gap-2">
            <Award className="h-5 w-5 text-secondary-500" />
            Badges Earned
          </h3>
          <div className="grid grid-cols-2 sm:grid-cols-3 gap-3">
            {badges.map((badge: string) => (
              <div 
                key={badge}
                className="flex items-center gap-2 p-2 bg-secondary-50 rounded-lg"
              >
                <Star className="h-4 w-4 text-secondary-500" />
                <span className="text-sm">{badge}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}