import React from 'react';
import { Outlet, Link } from 'react-router-dom';
import { Brain, Github, User, LogOut } from 'lucide-react';
import { useAuth } from '../hooks/useAuth';

export default function Layout() {
  const { user, logout } = useAuth();

  return (
    <div className="min-h-screen bg-brand-darkBg text-brand-cream">
      <header className="bg-brand-darkBlue border-b border-brand-blue/20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-between items-center">
            <Link to="/" className="flex items-center space-x-3">
              <Brain className="h-8 w-8 text-brand-blue" />
              <h1 className="text-2xl font-bold text-brand-cream">RiddleScout</h1>
            </Link>

            <div className="flex items-center gap-4">
              {user ? (
                <>
                  <Link 
                    to="/profile"
                    className="flex items-center gap-2 text-brand-cream/80 hover:text-brand-cream"
                  >
                    <User className="h-5 w-5" />
                    <span>{user.username}</span>
                  </Link>
                  <button
                    onClick={() => logout()}
                    className="flex items-center gap-2 text-brand-cream/80 hover:text-brand-cream"
                  >
                    <LogOut className="h-5 w-5" />
                    <span>Logout</span>
                  </button>
                </>
              ) : (
                <Link 
                  to="/login"
                  className="flex items-center gap-2 text-brand-cream/80 hover:text-brand-cream"
                >
                  <User className="h-5 w-5" />
                  <span>Login</span>
                </Link>
              )}
              
              <a
                href="https://github.com/yourusername/riddlescout"
                target="_blank"
                rel="noopener noreferrer"
                className="text-brand-cream/80 hover:text-brand-cream flex items-center gap-2"
              >
                <Github className="h-5 w-5" />
                <span>GitHub</span>
              </a>
            </div>
          </div>
        </div>
      </header>

      <main className="container mx-auto px-4 py-8">
        <Outlet />
      </main>

      <footer className="bg-brand-darkBlue border-t border-brand-blue/20 mt-auto">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <p className="text-center text-brand-cream/60">
            © {new Date().getFullYear()} RiddleScout. All rights reserved.
          </p>
        </div>
      </footer>
    </div>
  );
}