import React from 'react';
import { Brain, Tag } from 'lucide-react';

interface DifficultySelectorProps {
  selectedDifficulty: string;
  selectedCategory: string;
  onDifficultyChange: (difficulty: string) => void;
  onCategoryChange: (category: string) => void;
}

const difficulties = [
  { name: 'Random', points: '5-25' },
  { name: 'Novice Noodler', points: 5 },
  { name: 'Trailblazer', points: 10 },
  { name: 'Puzzle Prowler', points: 15 },
  { name: 'Enigma Explorer', points: 20 },
  { name: 'Master Riddler', points: 25 }
];

const categories = [
  'Random',
  'Science',
  'Nature',
  'Technology',
  'History',
  'Literature',
  'Mathematics',
  'Music',
  'Art',
  'Geography',
  'Space',
  'Food',
  'Sports',
  'Movies',
  'Animals'
];

export default function DifficultySelector({
  selectedDifficulty,
  selectedCategory,
  onDifficultyChange,
  onCategoryChange
}: DifficultySelectorProps) {
  return (
    <div className="flex flex-col sm:flex-row gap-4">
      <div className="flex-1">
        <label className="flex items-center gap-2 text-brand-cream/80 mb-2">
          <Brain className="h-5 w-5 text-brand-blue" />
          Difficulty Level
        </label>
        <select
          value={selectedDifficulty}
          onChange={(e) => onDifficultyChange(e.target.value)}
          className="w-full rounded-lg bg-brand-darkBlue border border-brand-blue/30 text-brand-cream p-2 focus:border-brand-blue focus:ring-1 focus:ring-brand-blue"
        >
          {difficulties.map(({ name, points }) => (
            <option key={name} value={name}>
              {name} {points ? `(${points} points)` : ''}
            </option>
          ))}
        </select>
      </div>

      <div className="flex-1">
        <label className="flex items-center gap-2 text-brand-cream/80 mb-2">
          <Tag className="h-5 w-5 text-brand-orange" />
          Category
        </label>
        <select
          value={selectedCategory}
          onChange={(e) => onCategoryChange(e.target.value)}
          className="w-full rounded-lg bg-brand-darkBlue border border-brand-blue/30 text-brand-cream p-2 focus:border-brand-orange focus:ring-1 focus:ring-brand-orange"
        >
          {categories.map((category) => (
            <option key={category} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}