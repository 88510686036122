import React from 'react';
import { Edit, Trash2, Eye } from 'lucide-react';
import { RiddleData } from '../../types/riddle';

interface RiddleTableProps {
  riddles: RiddleData[];
  onEdit: (riddle: RiddleData) => void;
  onDelete: (id: number) => void;
  loading: boolean;
}

export default function RiddleTable({
  riddles,
  onEdit,
  onDelete,
  loading
}: RiddleTableProps) {
  if (loading) {
    return <div>Loading riddles...</div>;
  }

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full bg-white rounded-lg shadow-lg">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">ID</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Riddle</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Answer</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Difficulty</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Category</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200">
          {riddles.map((riddle) => (
            <tr key={riddle.id}>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {riddle.id}
              </td>
              <td className="px-6 py-4">
                <div className="text-sm text-gray-900 line-clamp-2">{riddle.riddle}</div>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {riddle.answer}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {riddle.difficulty}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                {riddle.category}
              </td>
              <td className="px-6 py-4 whitespace-nowrap">
                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                  riddle.publicationStatus === 'Published'
                    ? 'bg-green-100 text-green-800'
                    : 'bg-yellow-100 text-yellow-800'
                }`}>
                  {riddle.publicationStatus}
                </span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                <div className="flex items-center gap-2">
                  <button
                    onClick={() => onEdit(riddle)}
                    className="text-blue-600 hover:text-blue-800"
                  >
                    <Edit className="h-4 w-4" />
                  </button>
                  <button
                    onClick={() => onDelete(riddle.id)}
                    className="text-red-600 hover:text-red-800"
                  >
                    <Trash2 className="h-4 w-4" />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}