import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import RiddleGame from './components/RiddleGame';
import Login from './components/Login';
import Register from './components/Register';
import UserProfile from './components/UserProfile';
import ProtectedRoute from './components/ProtectedRoute';
import RiddleManager from './components/admin/RiddleManager';

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<RiddleGame />} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />
        <Route path="profile" element={
          <ProtectedRoute>
            <UserProfile />
          </ProtectedRoute>
        } />
        <Route path="admin" element={
          <ProtectedRoute adminOnly>
            <RiddleManager />
          </ProtectedRoute>
        } />
      </Route>
    </Routes>
  );
}