import React, { useState, useEffect } from 'react';
import { Plus, Edit, Trash2, Save, X } from 'lucide-react';
import { RiddleData } from '../../types/riddle';
import { useAuth } from '../../hooks/useAuth';
import RiddleForm from './RiddleForm';
import RiddleTable from './RiddleTable';
import { fetchRiddleData } from '../../utils/googleSheets';

export default function RiddleManager() {
  const { user } = useAuth();
  const [riddles, setRiddles] = useState<RiddleData[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedRiddle, setSelectedRiddle] = useState<RiddleData | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadRiddles();
  }, []);

  const loadRiddles = async () => {
    try {
      const data = await fetchRiddleData();
      setRiddles(data);
    } catch (error) {
      console.error('Error loading riddles:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (riddle: RiddleData) => {
    setSelectedRiddle(riddle);
    setIsEditing(true);
  };

  const handleDelete = async (id: number) => {
    if (!confirm('Are you sure you want to delete this riddle?')) return;
    // Implement delete functionality
  };

  const handleSave = async (riddle: RiddleData) => {
    // Implement save functionality
    setIsEditing(false);
    setSelectedRiddle(null);
    await loadRiddles();
  };

  if (!user?.isAdmin) {
    return <div>Access denied. Admin privileges required.</div>;
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">Riddle Manager</h1>
        <button
          onClick={() => setIsEditing(true)}
          className="flex items-center gap-2 px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700"
        >
          <Plus className="h-4 w-4" />
          Add New Riddle
        </button>
      </div>

      {isEditing ? (
        <RiddleForm
          riddle={selectedRiddle}
          onSave={handleSave}
          onCancel={() => {
            setIsEditing(false);
            setSelectedRiddle(null);
          }}
        />
      ) : (
        <RiddleTable
          riddles={riddles}
          onEdit={handleEdit}
          onDelete={handleDelete}
          loading={loading}
        />
      )}
    </div>
  );
}