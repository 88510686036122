import React, { useState, useEffect } from 'react';
import { RiddleData } from '../../types/riddle';

interface RiddleFormProps {
  riddle?: RiddleData;
  onSave: (riddle: RiddleData) => void;
  onCancel: () => void;
}

const difficultyPoints = {
  'Novice Noodler': 5,
  'Trailblazer': 10,
  'Puzzle Prowler': 15,
  'Enigma Explorer': 20,
  'Master Riddler': 25
};

export default function RiddleForm({ riddle, onSave, onCancel }: RiddleFormProps) {
  const [formData, setFormData] = useState<Partial<RiddleData>>({
    riddle: '',
    answer: '',
    hints: ['', '', ''],
    difficulty: 'Novice Noodler',
    category: 'General',
    tags: [],
    imageDescription: '',
    alternateAnswers: [],
    explanation: '',
    ageGroup: 'All Ages',
    publicationStatus: 'Draft',
    ...riddle
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSave(formData as RiddleData);
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6 bg-white rounded-lg shadow-lg p-6">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">Riddle Text</label>
            <textarea
              value={formData.riddle}
              onChange={(e) => setFormData({ ...formData, riddle: e.target.value })}
              className="w-full h-32 rounded-lg"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Answer</label>
            <input
              type="text"
              value={formData.answer}
              onChange={(e) => setFormData({ ...formData, answer: e.target.value })}
              className="w-full rounded-lg"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Alternate Answers</label>
            <input
              type="text"
              value={formData.alternateAnswers?.join(', ')}
              onChange={(e) => setFormData({
                ...formData,
                alternateAnswers: e.target.value.split(',').map(s => s.trim())
              })}
              className="w-full rounded-lg"
              placeholder="Comma-separated list"
            />
          </div>

          <div className="space-y-2">
            <label className="block text-sm font-medium">Hints</label>
            {[0, 1, 2].map((i) => (
              <input
                key={i}
                type="text"
                value={formData.hints?.[i] || ''}
                onChange={(e) => {
                  const newHints = [...(formData.hints || [])];
                  newHints[i] = e.target.value;
                  setFormData({ ...formData, hints: newHints });
                }}
                className="w-full rounded-lg"
                placeholder={`Hint ${i + 1}`}
                required
              />
            ))}
          </div>
        </div>

        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">Difficulty ({difficultyPoints[formData.difficulty as keyof typeof difficultyPoints]} points)</label>
            <select
              value={formData.difficulty}
              onChange={(e) => setFormData({ ...formData, difficulty: e.target.value })}
              className="w-full rounded-lg"
              required
            >
              {Object.keys(difficultyPoints).map((diff) => (
                <option key={diff} value={diff}>{diff}</option>
              ))}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Category</label>
            <select
              value={formData.category}
              onChange={(e) => setFormData({ ...formData, category: e.target.value })}
              className="w-full rounded-lg"
              required
            >
              {/* Add your categories here */}
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Age Group</label>
            <select
              value={formData.ageGroup}
              onChange={(e) => setFormData({ ...formData, ageGroup: e.target.value })}
              className="w-full rounded-lg"
              required
            >
              <option>All Ages</option>
              <option>Children (5-12)</option>
              <option>Teens (13-17)</option>
              <option>Adults (18+)</option>
            </select>
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Image Description (AI Prompt)</label>
            <textarea
              value={formData.imageDescription}
              onChange={(e) => setFormData({ ...formData, imageDescription: e.target.value })}
              className="w-full h-32 rounded-lg"
              placeholder="Describe the image that should be generated..."
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Riddle Explanation</label>
            <textarea
              value={formData.explanation}
              onChange={(e) => setFormData({ ...formData, explanation: e.target.value })}
              className="w-full h-32 rounded-lg"
              placeholder="Explain the logic behind the riddle..."
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Tags</label>
            <input
              type="text"
              value={formData.tags?.join(', ')}
              onChange={(e) => setFormData({
                ...formData,
                tags: e.target.value.split(',').map(s => s.trim())
              })}
              className="w-full rounded-lg"
              placeholder="Comma-separated tags"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-end gap-4">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 text-gray-600 hover:text-gray-800"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 bg-primary-600 text-white rounded-lg hover:bg-primary-700"
        >
          Save Riddle
        </button>
      </div>
    </form>
  );
}